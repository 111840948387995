<template>
  <div style="height: 100%">
    <!-- 卡片视图 -->
    <el-card style="height: 100%;overflow:auto;">
      <!-- 顶部面包屑导航 -->
      <breadcrumb-nav>
        <template v-slot:firstMenu>液源管理</template>
        <template v-slot:secondMenu>液源管理</template>
      </breadcrumb-nav>
      <div style="fontSize: 20px;fontWeight: 400;color: #0a0b1a;">
        液源列表
      </div>
      <div class="radioBox">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="液源名称">
            <el-input autosize v-model="formInline.user" placeholder="请输入" @input="onSubmit"></el-input>
          </el-form-item>
          <el-form-item label="贸易企业" v-if="type=='2'">
            <el-select v-model="formInline.region" placeholder="请选择" @change='onSubmit'>
              <el-option
                    v-for="item in factoryList"
                    :key="item.tardId"
                    :label="item.tardName"
                    :value="item.tardName">
                  </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="液源类型" v-if="type=='1'">
            <el-select v-model="formInline.regions" placeholder="请选择" @change='onSubmit'>
              <el-option label="内采" value="1"></el-option>
              <el-option label="外采" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <add-record v-if="type==1" @plan-list="updateRoleList"/>
      </div>
      <!-- table表格区域 -->
      <el-table highlight-current-row :data="goodsList" border :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
        <el-table-column label="液源名称" prop="sourceName"></el-table-column>
        <el-table-column label="液源类型" prop="sourceType" v-if="type==1">
          <template slot-scope="scope">
            {{scope.row.sourceType==1?'内采':'外采'}}
          </template>
        </el-table-column>
        <el-table-column label="所属液厂企业" prop="tardName" v-if="type==1"></el-table-column>
        <el-table-column label="实时挂牌价(元/吨)" prop="price" ></el-table-column>
        <el-table-column v-if="type==2" label="贸易企业" prop="tardName"></el-table-column>
        <el-table-column label="生效时间" prop="effectTime" width="170px"></el-table-column>
        <el-table-column label="操作" width="200px">
          <template slot-scope="scope">
            <div style="display: flex">
              <record-price-modal :goodsScope="scope.row" @plan-list="updateRoleList"/>
              <edit-record v-if="type==1" :role="scope.row" @plan-list="updateRoleList"/>
              <remove-record v-if="type==1" :row-id="scope.row.sourceId" @plan-list="updateRoleList"/>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="float: right;">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.pageNumber"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
  import BreadcrumbNav from "../common/BreadcrumbNav";
  import RecordPriceModal from './modal/recordPriceModal';

  import {
    getGoodsListRequest,getFactoryRequest,getEnterpriseRequest,getGoodsListOneRequest
  } from "network/goods";
import AddRecord from './modal/addRecord.vue';
import EditRecord from './modal/editRecord.vue';
import RemoveRecord from './modal/removeRecord.vue';


  export default {
    name: "List",
    components: {
      BreadcrumbNav,
      RecordPriceModal,
        AddRecord,
        EditRecord,
        RemoveRecord,

    },
    data() {
      return {
        queryInfo: {
          pageNumber: 1,
          pageSize: 10
        },
        formInline: {
          user: '',
          region: '',
          regions: ''

        },
        goodsList: [],
        factoryList: [],
        total: 0,
        type: localStorage.getItem('type')
      }
    },
    created() {
      this.getGoodsList();
      this.getFactory()
    },
    methods: {
      onSubmit() {
        this.getGoodsList()
      },
      getFactory(){
        getEnterpriseRequest({
          stationId: localStorage.getItem('enterpriseId')
        }).then(res => {
          let result = res.data;
          if (result.code !== 200) {

          }
          this.factoryList = result.data;
        })
      },

      getGoodsList() {
        if(localStorage.getItem('type')=='1'){
          getGoodsListRequest({
          ...this.queryInfo,
          tradeName: this.formInline.region,
          sourceName: this.formInline.user,
          sourceType: this.formInline.regions,
          enterpriseId: localStorage.getItem('enterpriseId')
          }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取液源数据失败', 'error');
          }
          this.goodsList = result.data.list;
          this.total = result.data.total;
        });
        }else{
          getGoodsListOneRequest({
          ...this.queryInfo,
          tradeName: this.formInline.region,
          sourceName: this.formInline.user,
          sourceType: this.formInline.regions,
          stationId: localStorage.getItem('enterpriseId')
          }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取液源数据失败', 'error');
          }
          this.goodsList = result.data.list;
          this.total = result.data.total;
        });
        }

      },

      // 每页显示的数据条数发送变化
      handleSizeChange(newSize) {
        this.queryInfo.pageNumber = 1
        this.queryInfo.pageSize = newSize;
        this.getGoodsList();
      },

      // 当前页码发生变化
      handleCurrentChange(newPage) {
        this.queryInfo.pageNumber = newPage;
        this.getGoodsList();
      },

      updateRoleList(){
        this.getGoodsList()
      },

    }
  }
</script>

<style scoped>
.radioBox{
  margin-top: 30px;
  margin-bottom: 15px;
}
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }

</style>
