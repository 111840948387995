<template>
<div>
    <el-row>
      <el-col>
        <el-button type="text" @click="priceRecord(goodsScope)">价格记录</el-button>
        <el-button v-if="type=='2'" type="text" @click="ArrangePlan(goodsScope.sourceId)">下计划</el-button>
      </el-col>
    </el-row>

  <el-dialog
            title="价格记录"
            :visible.sync="setRightDialogVisible"
            width="70%">
      <div>液源名称：{{goodsScope.sourceName}}</div>
      <el-table highlight-current-row :data="priceList" border :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
        <el-table-column label="挂牌价(元/吨)" prop="price"></el-table-column>
        <el-table-column label="创建时间" prop="createDate" ></el-table-column>
        <el-table-column label="生效时间" prop="effectTime" ></el-table-column>
        <el-table-column label="操作人" prop="createUser" ></el-table-column>
      </el-table>
      <!-- <template> -->
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.pageNumber"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      <!-- </template> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRightDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setRightDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    </div>
</template>
<script>
import {getGoodsPriceListRequest} from 'network/goods'
export default {
  name: "RecordPriceModal",
  props: {
    goodsScope: {
      type: Object,
    }
  },
  data() {
    return {
      property: 'value',
      setRightDialogVisible: false,
      type: localStorage.getItem('type'),
      queryInfo: {
          pageNumber: 1,
          pageSize: 10
        },
      total: 5,  
      priceList: []      
    };
  },
  methods: {
    priceRecord(scope){
      console.log(scope);
      this.setRightDialogVisible = true
      getGoodsPriceListRequest({
        ...this.queryInfo,
        sourceName: scope.sourceName,
        sourceId: scope.sourceId,
        tardId: scope.enterpriseId
      }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取液源价格数据失败', 'error');
          }
          this.priceList = result.data.list;
          this.total = result.data.total;
      });
    },

    handleSizeChange(newSize) {
        this.queryInfo.pageNumber = 1
        this.queryInfo.pageSize = newSize;
        this.priceRecord(this.goodsScope)
      },

      // 当前页码发生变化
      handleCurrentChange(newPage) {
        this.queryInfo.pageNumber = newPage;
        this.priceRecord(this.goodsScope)
      },

      ArrangePlan(){
        this.$router.replace({path: '/pages/biz/gas_factory/plan'})
        this.$router.go(0)
        localStorage.setItem('activePath','/pages/biz/gas_factory/plan')
      }
  }
}
</script>
<style scoped>
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
</style>
