<template>
<div>
    <el-row>
      <el-col>
        <el-button type="text"  @click="open(role)">编辑</el-button>
      </el-col>
    </el-row>

  <el-dialog
            title="编辑液源"
            :visible.sync="setRightDialogVisible"
            :append-to-body='true'
            :modal-append-to-body='true'
            width="50%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-form-item  label="液源名称" prop="name" >
          <el-input v-model="ruleForm.name" placeholder="请输入液源名称" style="width: 500px;" readonly></el-input>
        </el-form-item>
        <el-form-item label="液源类型" prop="region">
          <el-select v-model="ruleForm.region" placeholder="请选择" style="width: 500px;" disabled>
            <el-option label="内采" value="1"></el-option>
            <el-option label="外采" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="液厂企业" prop="region2">
          <el-select v-model="ruleForm.region2" placeholder="请选择液厂企业" style="width: 500px;" disabled>
            <el-option
                v-for="item in factoryList"
                :key="item.factoryId"
                :label="item.factoryName"
                :value="item.factoryId">
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="挂牌价" prop="price" >
          <el-input v-model="ruleForm.price" placeholder="请输入挂牌价" style="width: 500px;"></el-input>
        </el-form-item>
        <el-form-item label="价格生效时限" prop="region3">
          <el-select v-model="ruleForm.region3" placeholder="请选择" style="width: 500px;">
            <el-option label="立即生效" value="1"></el-option>
            <el-option label="固定时间生效" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="生效时间" required prop="date" v-if="ruleForm.region3==2">
          <el-date-picker v-model="ruleForm.date" type="datetime" placeholder="请选择生效时间" :picker-options="startDateDisabled"
                                    default-time="12:00:00" style="width: 500px;" value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
    </div>
</template>
<script>
import { getFactoryRequest,editFactoryRequest } from "network/goods";
export default {
  name: "addRecord",
  props: {
    role: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      property: 'value',
      setRightDialogVisible: false,
      ruleForm: {
          name: '',
          region: '',
          region2: '',
          region3: '',
          price: '',
          date: '',

        },
      rules: {
        name: [
          { required: true, message: '请输入液源名称', trigger: 'blur' },
        ],
        region: [
          { required: true, message: '请选择液源类型', trigger: 'change' }
        ],
        region2: [
          { required: true,message: '请选择液厂企业', trigger: 'change'}
        ],
        price: [
          { required: true, message: '请输入挂牌价', trigger: 'blur' },
        ],
        region3: [
          { required: true,message: '请选择生效时限', trigger: 'change'}
        ],
        date: [
          { required: true, message: '请选择日期', trigger: 'change' }
        ],
      },
      factoryList: [],

    };
  },
  created() {
      this.getList();
    },
  methods: {
    open(role){
        console.log(role)
      this.setRightDialogVisible = true
      this.ruleForm={
        name: role.sourceName,
        region: role.sourceType.toString(),
        region2: role.factoryId,
        region3: role.effectType.toString(),
        price: role.price,
        date: role.effectTime,
      }
    },
    getList(){
       getFactoryRequest({}).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取列表数据失败', 'error');
          }
          this.factoryList = result.data;
        });
    },
    submitForm(formName) {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            editFactoryRequest({
                sourceId: this.role.sourceId,
                sourceName: this.ruleForm.name,
                sourceType: this.ruleForm.region,
                price: this.ruleForm.price,
                factoryId: this.ruleForm.region2,
                effectType: this.ruleForm.region3,
                effectTimeStr: this.ruleForm.date
              }).then(res=>{
                let result = res.data;
                if (result.code !== 200) {
                  return this.alertMessage('修改失败', 'error');
                }
                this.alertMessage('修改成功', 'success');
                // 隐藏弹窗
                this.setRightDialogVisible=false
                this.$refs.ruleForm.resetFields();
                this.$emit('plan-list');
              })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.setRightDialogVisible=false
        this.$refs[formName].resetFields();
      }
  }
}
</script>
<style scoped>
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
</style>
